<template>
	<div>
		<div class="container-fluid bg-pack-page">
			<div class="row">
				<div class="col-12">
					<div class="container pt-md-4 pb-4 pb-md-0">
						<div class="row">
							<div class="col-12 col-md-8 mt-md-4">
								<h2 class="pack-name mt-3 d-lg-none d-block">
									{{
										$t('message.pack_name', {
											pack : $t(`message.pack[0].${pack.pack_name}`),
										})
									}}
								</h2>
								<p class="car-title mb-lg-0 mb-3">
									{{ pack.car_name }}
								</p>
								<div class="car-price">
									<span>{{ $t('message.pack_since') }}</span>
									<div class="row">
										<div class="col-auto pr-0">
											<div class="price">{{ pack.price }}</div>
										</div>
										<div class="col-auto pl-1">
											<div class="symbol">€</div>
											<span class="dia">/{{ $t('message.pack_day') }}</span>
										</div>
									</div>
								</div>
								<img
									class="img-fluid pack-img"
									:src="setImg(pack.img_src)"
									alt=""
								/>
								<div class="car-stats pb-2 pb-md-0">
									<div class="row text-center">
										<div class="col-4 col-md-2 pb-2">
											<div class="car-tag">
												<!-- <img
																			src="../assets/images/icons/combustivel.svg" alt=""> -->
												{{
													$t('message.fuel', {
														fueltype : $t(`message.fueltype[0].${pack.fuel}`),
													})
												}}
											</div>
										</div>
										<div class="col-4 col-md-2 pb-2">
											<div class="car-tag">
												<!-- <img src="../assets/images/icons/lugares.svg"
																			 alt=""> -->
												{{ pack.seats }}
												{{ $t('message.seats') }}
											</div>
										</div>
										<div class="col-4 col-md-2 pb-2">
											<div class="car-tag">
												<!-- <img src="../assets/images/icons/portas.svg"
																			 alt=""> -->
												{{ pack.doors }}
												{{ $t('message.doors') }}
											</div>
										</div>
										<div class="col-4 col-md-2 pb-2">
											<div class="car-tag">
												<!-- <img src="../assets/images/icons/mudancas.svg"
																			 alt=""> -->
												{{
													$t('message.drivetrain', {
														dtrain : $t(`message.dtrain[0].${pack.drivetrain}`),
													})
												}}
											</div>
										</div>
										<div class="col-4 col-md-2 pb-2" v-if="pack.ac">
											<div class="car-tag">
												<!-- <img
																			src="../assets/images/icons/ac.svg" alt="">  -->
												{{ $t('message.ac') }}
											</div>
										</div>
										<div class="col-4 col-md-2 pb-2">
											<div class="car-tag">
												<!-- <img
																			src="../assets/images/icons/malas.svg" alt=""> -->
												{{ $tc('message.luggage', pack.luggage) }}
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="col-12 col-md-4 mt-3 mb-2 search-form">
								<div class="row">
									<div class="col-12">
										<form @submit.prevent="submit">
											<div class="col-12 pt-2 pb-2 text-center">
												<h2>{{ $t('message.form.title') }}</h2>
											</div>
											<div
												class="alert alert-danger"
												role="alert"
												v-if="errors"
												v-html="errormsg"
											></div>
											<h3>{{ $t('message.form.pickup') }}</h3>
											<div
												class="input-group mb-2 mt-2"
												:class="{ hasError: $v.form.pickup_location.$error }"
											>
												<div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1"
						  ><i class="fas fa-map-marker-alt"></i
						  ></span>
												</div>
												<select
													v-bind:placeholder="$t('message.form.s1') + '*'"
													v-model="form.pickup_location"
													class="form-control"
												>
													<option value="" selected>
														{{ $t('message.form.s1') }} *
													</option>
													<option value="Leiria - Carvoeiros">
														{{ $t('message.form.location1') }}
													</option>
													<option value="Leiria - Zona Industrial">
														{{ $t('message.form.location2') }}
													</option>
													<option value="Caldas da Rainha">
														{{ $t('message.form.location3') }}
													</option>
													<option value="Aeroporto de Lisboa/Porto">
														{{ $t('message.form.location4') }}
													</option>
												</select>
											</div>

											<div class="d-flex justify-content-around mb-3 mt-2">
												<div
													class="form-group right-square"
													style="width: 60%; margin-bottom: 0"
													:class="{ hasError: $v.form.pickup_date.$error }"
												>
													<datepicker
														v-bind:placeholder="$t('message.form.s3') + '*'"
														v-model="form.pickup_date"
														:bootstrap-styling="true"
														:disabled-dates="state.disabledDates"
														name="pickup_date"
														:language="localeLng"
														:calendar-button="true"
														:calendar-button-icon="'far fa-calendar-alt'"
														@input="handler"
														:highlighted="highlighted"
													></datepicker>
												</div>
												<div
													class="input-group"
													style="width: 40%"
													:class="{ hasError: $v.form.pickup_time.$error }"
												>
													<div class="input-group-prepend">
                            <span class="input-group-text"
							><i class="far fa-clock"></i
							></span>
													</div>
													<select
														class="form-control left-square"
														name="pickup_time"
														v-model="form.pickup_time"
														@change="setHours"
													>
														<option value="" disabled selected>
															{{ $t('message.form.hours') }}
															*
														</option>
														<option v-for="item in schedules" :value="item">
															{{ item }}
														</option>
													</select>
												</div>
											</div>

											<div class="control-group">
												<label class="control control-checkbox">
													{{ $t('message.form.delivery_change') }}
													<input
														type="checkbox"
														checked="checked"
														v-model="form.delivery_change"
														value="true"
														@change="sizeChange(form.delivery_change)"
													/>
													<div class="control_indicator"></div>
												</label>
											</div>

											<h3 class="mt-3">
												{{ $t('message.form.delivery') }}
											</h3>

											<div
												class="input-group mb-2 mt-3"
												v-if="form.delivery_change === true"
												:class="{ hasError: $v.form.delivery_location.$error }"
											>
												<div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon2"
						  ><i class="fas fa-map-marker-alt"></i
						  ></span>
												</div>
												<select
													v-bind:placeholder="$t('message.form.s2') + '*'"
													v-model="form.delivery_location"
													class="form-control"
												>
													<option value="" selected>
														{{ $t('message.form.s2') }} *
													</option>
													<option value="Leiria - Carvoeiros">
														{{ $t('message.form.location1') }}
													</option>
													<option value="Leiria - Zona Industrial">
														{{ $t('message.form.location2') }}
													</option>
													<option value="Caldas da Rainha">
														{{ $t('message.form.location3') }}
													</option>
													<option value="Aeroporto de Lisboa/Porto">
														{{ $t('message.form.location4') }}
													</option>
												</select>
											</div>

											<div class="d-flex justify-content-around mb-2 mt-2">
												<div
													class="form-group right-square"
													style="width: 60%; margin-bottom: 0"
													:class="{ hasError: $v.form.delivery_date.$error }"
												>
													<datepicker
														v-bind:placeholder="$t('message.form.s4') + '*'"
														v-model="form.delivery_date"
														:bootstrap-styling="true"
														:calendar-button="true"
														:calendar-button-icon="'far fa-calendar-alt'"
														name="delivery_date"
														:disabled-dates="state.disabledDates2"
														@input="setDate"
														:highlighted="highlighted"
														:language="localeLng"
													></datepicker>
												</div>
												<div
													class="input-group"
													style="width: 40%"
													:class="{ hasError: $v.form.delivery_time.$error }"
												>
													<div class="input-group-prepend">
                            <span class="input-group-text"
							><i class="far fa-clock"></i
							></span>
													</div>
													<select
														class="form-control left-square"
														style="width: 40%"
														name="delivery_time"
														v-model="form.delivery_time"
													>
														<option value="" disabled selected>
															{{ $t('message.form.hours') }}
															*
														</option>
														<option
															v-for="item in schedules2"
															v-bind:value="item"
														>
															{{ item }}
														</option>
													</select>
												</div>
											</div>

											<p class="pt-2">* {{ $t('message.form.required') }}</p>
											<button class="btn btn-primary btn-block mt-2 mb-2">
												{{ $t('message.form.pre_reservation') }}
											</button>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="mp-location-bg-2 pb-5">
			<div class="container">
				<div class="row pt-5 pb-3">
					<div class="col-12 text-center pb-4">
						<h2>{{ $t('message.mp_title4') }}</h2>
					</div>
				</div>
				<div class="row pb-5">
					<div class="col-12">
						<carousel></carousel>
					</div>
				</div>
			</div>
		</div>
		<div class="mp-dark-bg">
			<div class="container">
				<WhyZone></WhyZone>
			</div>
		</div>

		<div class="mp-yellow-bg">
			<div class="container">
				<div class="row">
					<div class="col-12">
						<div class="d-md-flex justify-content-md-between pb-3 pb-md-0">
							<div class="d-md-flex justify-content-md-start content">
								{{ $t('message.services.doubt') }}<span class="pl-2" style="color:white"> {{
									$t('message.services.contactus')
								}}!</span>
							</div>

							<div class="d-md-flex justify-content-md-end content">
								<a href="tel:+351 924 114 635"><i class="fas fa-phone mr-2"></i>+351 924 114 635</a>
								<router-link class="btn btn-primary mt-2 mt-md-0"
											 :to="{name:'contacts', params : {lang:lang}}">
									<i
										class="far fa-envelope"></i> {{ $t('message.services.contactus') }}
								</router-link>
							</div>
						</div>
						<p class="text-right" style="font-size:0.75rem;font-weight:400;margin: 15px;">{{ $t('message.cost_mobile') }}</p>
					</div>
				</div>
			</div>
		</div>
		<div class="mp-location-bg pt-5 pb-5">
			<Location></Location>
		</div>
	</div>
</template>


<script>
import Datepicker     from 'vuejs-datepicker';
import {en, ptBR}     from 'vuejs-datepicker/dist/locale';
import {required}     from 'vuelidate/lib/validators';
import json           from '../../website-data.json';
import CustomCarousel from '../components/Carousel';
import Location       from '../components/Location';
import WhyZone        from '../components/WhyZone';

let today     = new Date();
let yesterday = new Date(
	today.getFullYear(),
	today.getMonth(),
	today.getDate()
);

export default {
	name       : 'PackPage',
	components : {
		Datepicker,
		WhyZone,
		Location,
		carousel : CustomCarousel,
	},
	data       : function () {
		return {
			times       : [
				'09:00',
				'10:00',
				'11:00',
				'12:00',
				'13:00',
				'14:00',
				'15:00',
				'16:00',
				'17:00',
				'18:00',
			],
			schedules   : [
				'09:00',
				'10:00',
				'11:00',
				'12:00',
				'13:00',
				'14:00',
				'15:00',
				'16:00',
				'17:00',
				'18:00',
			],
			schedules2  : [
				'09:00',
				'10:00',
				'11:00',
				'12:00',
				'13:00',
				'14:00',
				'15:00',
				'16:00',
				'17:00',
				'18:00',
			],
			highlighted : {
				to   : '',
				from : '',
			},
			state       : {
				disabledDates  : {
					to : yesterday, // Disable all dates up to specific date
				},
				disabledDates2 : {
					to : yesterday,
				},
			},
			disabled    : false,
			step        : 1,
			json        : json,
			localeLng   : this.setLocaleLng(),
			lang        : this.$route.params.lang,
			pack        : this.setPacks(),
			form        : {
				pack              : this.$route.params.pack_name,
				delivery_location : '',
				delivery_change   : false,
				pickup_location   : '',
				pickup_date       : '',
				pickup_time       : '',
				delivery_date     : '',
				delivery_time     : '',
			},
			success     : false,
			loadSpinner : false,
			errors      : false,
			errormsg    : '',
		};
	},
	computed   : {
		rules() {
			return {
				pickup_date       : { required },
				delivery_date     : { required },
				pickup_location   : { required },
				pickup_time       : { required },
				delivery_time     : { required },
				delivery_location :
					this.form.delivery_change === true ? { required } : {},
			};
		},
		path() {
			return this.$store.getters.setLocale;
		},
	},
	validations() {
		return {
			form : this.rules,
		};
	},
	beforeRouteEnter(to, from, next) {
		next();
	},
	watch : {
		// call the method again if the route changes
		$route : 'asyncData',
	},
	created() {
		// Initial load fetch your data
		this.asyncData();
	},
	methods : {
		handler() {
			this.form.delivery_date = this.$moment(this.form.pickup_date).add(1, 'd').format('YYYY-MM-DD');
			this.setDate();
			this.setTime();
		},
		sizeChange(val) {
			let form = document.getElementsByClassName('search-form');
			console.log(form);
			if (val) {
				form[0].style.height = '100%';
			} else {
				form[0].style.height = '450px';
			}
		},
		setTime() {
			let date = new Date(this.form.pickup_date);
			let now  = new Date();
			let time = this.$moment(now).format('HH:mm');
			if (this.$moment(date).format('YYYY-MM-DD') === this.$moment(now).format('YYYY-MM-DD')) {
				let schedules  = this.schedules.filter((e) => {
					return e > time;
				});
				this.schedules = schedules;
			} else {
				this.schedules = this.times;
			}
		},
		setHours() {
			let date         = new Date(this.form.pickup_date);
			let deliveryDate = new Date(this.form.delivery_date);
			let time         = this.form.pickup_time.split(':');
			let hours        = '';
			if (this.$moment(date).format('YYYY-MM-DD') === this.$moment(deliveryDate).format('YYYY-MM-DD')) {
				if (this.form.pickup_time !== '') {
					this.schedules2 = this.schedules2.filter((e) => {
						return e > this.form.pickup_time;
					});
					if (parseInt(time[0]) >= 9 && parseInt(time[0]) < 18) {
						hours = parseInt(time[0]) + 1;
						hours = (hours == 9 ? '0' + hours : hours) + ':00';
					} else {
						hours = '';
					}
					this.form.delivery_time = hours;
				}
			} else {
				this.schedules2 = this.times;
				if (parseInt(time[0]) >= 9 && parseInt(time[0]) <= 18) {
					hours = parseInt(time[0]);
					hours = (hours == 9 ? '0' + hours : hours) + ':00';
				} else {
					hours = '';
				}
				this.form.delivery_time = hours;
			}
		},
		setDate() {
			let date                     = new Date(this.form.pickup_date);
			let deliveryDate             = new Date(this.form.delivery_date);
			this.state.disabledDates2.to = new Date(date.getFullYear(), date.getMonth(), date.getDate());
			this.highlighted.to          = new Date(deliveryDate.getFullYear(), deliveryDate.getMonth(), deliveryDate.getDate());
			this.highlighted.from        = new Date(date.getFullYear(), date.getMonth(), date.getDate());
			this.setHours();
		},
		asyncData() {
			this.pack = this.setPacks();
		},
		setLocaleLng() {
			const lang = this.$route.params.lang;
			if (lang === 'pt') return ptBR;
			else if (lang === 'en') return en;
		},
		setPacks() {
			let val = json.packs.filter((e) => {
				return e.pack_route === this.$route.params.pack_name;
			});

			return val[0];
		},
		submit(e) {
			e.preventDefault();
			this.$v.form.$touch();
			if (this.$v.form.$error) {
				this.errors = true;
				setTimeout(() => {
					this.errors = false;
				}, 10000);
				this.errormsg =
					this.lang === 'pt'
						? 'Preencha os campos obrigatórios.'
						: 'Required fields are missing.';
				return;
			}
			let vm           = this;
			let deliveryDate = vm.$moment(vm.form.delivery_date).format('DD/MM/YYYY');
			let pickupDate   = vm.$moment(vm.form.pickup_date).format('DD/MM/YYYY');
			if (new Date(pickupDate) > new Date(deliveryDate)) {
				this.errors = true;
				setTimeout(() => {
					this.errors = false;
				}, 10000);
				this.errormsg =
					this.lang === 'pt'
						? 'A data de entrega não pode ser inferior à data de recolha.'
						: 'Delivery date cannot be lower than pickup date.';
				return;
			}
			vm.form.delivery_date = deliveryDate;
			vm.form.pickup_date   = pickupDate;

			if (vm.$gtm.enabled()) {
				window.dataLayer?.push({
					event          : 'form_click',
					category       : 'lead_form',
					action         : 'alugar | Step 1',
					label          : vm.pack.car_name,
					value          : 0,
					noninteraction : false // Optional
				});
			}

			this.$router
				.push({ path : `/${vm.lang}/pre-reservation`, query : vm.form })
				.catch((err) => {
				});
			e.target.reset();
		},
		setImg(val) {
			let screen = window.innerWidth < 768 ? 'mobile' : 'desktop';
			return `https://vault13.rotauto.com/sites/Lubrirent/cars/${val}`;
		},
	},
};
</script>
